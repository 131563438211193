import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify, {
  iconfont: 'md',
  options: {
    customProperties: true
  },
  theme: {
    primary: '#8BC34A',
    secondary: '#9DCE4D',
    tertiary: '#9FE8FF',
    yellow: '#FFE27D'
  },
})
