import Vue from 'vue'
import Router from 'vue-router'
const Content = () => import(/* webpackChunkName: "Home" */ './views/Content.vue')
const Home = () => import(/* webpackChunkName: "Home" */ './views/Home.vue')

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Home,
      children: [
        {path: '/', component: Content}
      ]
    },
  ]
})
